import React from "react";
import { HelmetProvider } from "react-helmet-async";
import { Route, Routes } from "react-router-dom";
import About from "./pages/About";
import Home from "./pages/Home";
import NotFound from "./pages/NotFound";
import Details from "./pages/Details";
import Contact from "./pages/Contact";
import ModalProvider from "./context/providers/ModalProvider";
import NavProvider from "./context/providers/NavProvider";
import DestinationsProvider from "./context/providers/DestinationsProvider";
import SharedProvider from "./context/providers/SharedProvider";
import GalleryProvider from "./context/providers/GalleryProvider";
import AnimationsProvider from "./context/providers/AnimationsProvider";
import Nav from "./components/Nav";
import Toggle from "./components/Toggle";
//console.log(ModalContext);

function App() {
  return (
    <ModalProvider>
      <NavProvider>
        <GalleryProvider>
          <DestinationsProvider>
            <SharedProvider>
              <AnimationsProvider>
                <Toggle />
                <Nav />
                <HelmetProvider>
                  <Routes>
                    <Route path="/" element={<Home />} />
                    <Route path="/about" element={<About />} />
                    <Route path="/contact" element={<Contact />} />
                    <Route path="/details/:id" element={<Details />} />
                    <Route path="*" element={<NotFound />} />
                  </Routes>
                </HelmetProvider>
              </AnimationsProvider>
            </SharedProvider>
          </DestinationsProvider>
        </GalleryProvider>
      </NavProvider>
    </ModalProvider>
  );
}

export default App;

// 22
