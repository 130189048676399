import React, { useReducer } from "react";
import GallerContext from "../GalleryContext";
import GalleryReducer from "../reducers/GalleryReducer";
import gallery from "../../data/gallery";

const GalleryProvider = (props) => {
  const [galleryStore, dispatch] = useReducer(GalleryReducer, {
    gallery, //gallery: gallery, short gallery
    lightBoxStatus: false,
    currentLightBox: {},
  });
  return (
    <GallerContext.Provider value={{ galleryStore, dispatch }}>
      {props.children}
    </GallerContext.Provider>
  );
};

export default GalleryProvider;
