const reviews = [
	{
		id: 1,
		name: 'ali',
		comment:
			"That's great travel agency so far I see with great team and user friendly",
		stars: 4,
		image: '/assets/reviewUsers/user1.jpg',
	},
	{
		id: 2,
		name: 'alex',
		comment:
			'I suggest travel friends to everyone because I love travel friends',
		stars: 5,
		image: '/assets/reviewUsers/user2.jpg',
	},
	{
		id: 3,
		name: 'andrison',
		comment: 'One my favorite travel website',
		stars: 3,
		image: '/assets/reviewUsers/user3.jpg',
	},
	{
		id: 4,
		name: 'brad hussy',
		comment: 'I love the hotels which are associated with travel friends.',
		stars: 5,
		image: '/assets/reviewUsers/user4.jpg',
	},
	{
		id: 5,
		name: 'rahul gandi',
		comment:
			'I and my wife both are big fans of the travel friends website because they have everything that you want, 5-star rooms, friendly tour guides, cheap packages, and special discounts for couples, my final words must do one tour with travel friends after you also love travel friends.',
		stars: 5,
		image: '/assets/reviewUsers/user6.jpg',
	},
	{
		id: 6,
		name: 'shakil khan',
		comment:
			"I love the facilities especially when I reached to USA, travel friend's car was present there for me.",
		stars: 2,
		image: '/assets/reviewUsers/user5.jpg',
	},
	{
		id: 7,
		name: 'newton roy',
		comment: 'Thank you travel friends for the great adventure.',
		stars: 5,
		image: '/assets/reviewUsers/user7.jpg',
	},
	{
		id: 8,
		name: 'alex jobs',
		comment:
			'I did travel with some other agencies as well but travel friends is more professional than others.',
		stars: 5,
		image: '/assets/reviewUsers/user8.jpg',
	},
	{
		id: 9,
		name: 'sikandar khan',
		comment: '100% recommended to everyone',
		stars: 5,
		image: '/assets/reviewUsers/user9.jpg',
	},
];
export default reviews;
