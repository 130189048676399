import React, { useContext, useState } from "react";
import SharedContext from "../../context/SharedContext";
import ReviewBody from "./ReviewBody";

const Reviews = () => {
  const {
    sharedData: { reviews },
  } = useContext(SharedContext);

  const [heading] = useState("What our customers are saying?");
  return (
    <div className="reviews">
      <div className="container">
        <h2 className="heading animation">{heading}</h2>
        <div className="row ml-minus-15 mr-minus-15">
          {reviews.length > 0
            ? reviews.map((review, index) => (
                <ReviewBody key={review.id} review={review} />
              ))
            : ""}
        </div>
      </div>
    </div>
  );
};

export default Reviews;
