import React, { useState } from "react";

const ContactForm = () => {
  const [inputs, setInputs] = useState({
    name: "",
    email: "",
    message: "",
  });

  const submitContact = (e) => {
    e.preventDefault();
    //console.log(inputs);
  };
  return (
    <form onSubmit={submitContact}>
      <div className="group">
        <h3 className="page__contact_heading">Contact Form</h3>
      </div>
      <div className="group">
        <input
          type="text"
          name=""
          className="group__control"
          placeholder="Your name eg. Touheed"
          value={inputs.name}
          onChange={(e) => setInputs({ ...inputs, name: e.target.value })}
        />
      </div>
      <div className="group">
        <input
          type="email"
          name="email"
          className="group__control"
          placeholder="Your email eg. touheed@email.com"
          value={inputs.email}
          onChange={(e) => setInputs({ ...inputs, email: e.target.value })}
        />
      </div>
      <div className="group">
        <textarea
          cols="12"
          rows="8"
          name="message"
          className="group__textarea"
          placeholder="Write your message"
          defaultValue={inputs.message}
          onChange={(e) => setInputs({ ...inputs, message: e.target.value })}
        ></textarea>
      </div>
      <div className="group">
        <input type="submit" value="send email &rarr;" className="btn-dark" />
      </div>
    </form>
  );
};

export default ContactForm;
