import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import PageContainer from "../components/pages-parts/PageContainer";
import ContactForm from "../components/pages-parts/ContactForm";

const Contact = () => {
  const [state] = useState({
    heading: "Contact us",
    pageHeading: "Leave your message in the contact form",
    message:
      "Lorem, ipsum dolor sit amet consectetur adipisicing elit. Consequuntur esse est nam voluptatum corrupti animi vitae minus accusamus numquam quod eligendi, eos tempora dolores, eveniet, nobis dolorum deleniti sint sed! Qui, veritatis aut. Atque officia, dolorem quo illo cumque soluta, facilis voluptatum libero eos unde minus consequuntur ab, nam earum.",
  });
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <Helmet>
        <title>Travel Friends | Contact</title>
        <meta name="description" content="Travel friends contact page" />
      </Helmet>
      <PageContainer data={state}>
        <ContactForm />
      </PageContainer>
    </>
  );
};

export default Contact;
