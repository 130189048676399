import React, { useState, useContext } from "react";
import ModalContext from "../context/ModalContext";
import { OPEN_MODAL } from "../context/types/ModalTypes";

const Register = (props) => {
  const { dispatch } = useContext(ModalContext);
  const [inputs, setInputs] = useState({
    name: "",
    email: "",
    password: "",
  });
  const registerFormSubmit = (e) => {
    e.preventDefault();
    //console.log(inputs);
  };
  return (
    <form onSubmit={registerFormSubmit}>
      <div className="modal__heading">
        <h3>Create new account</h3>
      </div>
      <div className="group">
        <input
          type="text"
          name=""
          className="group__control"
          placeholder="Enter name"
          onChange={(e) => setInputs({ ...inputs, name: e.target.value })}
          value={inputs.name}
        />
      </div>
      <div className="group">
        <input
          type="email"
          name=""
          className="group__control"
          placeholder="Enter email"
          onChange={(e) => setInputs({ ...inputs, email: e.target.value })}
          value={inputs.email}
        />
      </div>
      <div className="group">
        <input
          type="password"
          name=""
          className="group__control"
          placeholder="Create Password"
          onChange={(e) => setInputs({ ...inputs, password: e.target.value })}
          value={inputs.password}
        />
      </div>
      <div className="group flex space-between y-center">
        <input type="submit" name="" className="btn-dark" value="Register" />
        <span
          onClick={() =>
            dispatch({ type: OPEN_MODAL, payload: props.currentModal })
          }
        >
          Already have an account?
        </span>
      </div>
    </form>
  );
};

export default Register;
