import React, { useContext, useState } from "react";
import ModalContext from "../context/ModalContext";
import { OPEN_MODAL } from "../context/types/ModalTypes";

const Login = (props) => {
  const { dispatch } = useContext(ModalContext);
  const [inputs, setInputs] = useState({
    email: "",
    password: "",
  });

  const loginFormSubmit = (e) => {
    e.preventDefault();
    //console.log(inputs);
  };
  return (
    <form onSubmit={loginFormSubmit}>
      <div className="modal__heading">
        <h3>Login</h3>
      </div>
      <div className="group">
        <input
          type="email"
          name=""
          className="group__control"
          placeholder="Enter email"
          onChange={(e) => setInputs({ ...inputs, email: e.target.value })}
          value={inputs.email}
        />
      </div>
      <div className="group">
        <input
          type="password"
          name=""
          className="group__control"
          placeholder="Enter Password"
          onChange={(e) => setInputs({ ...inputs, password: e.target.value })}
          value={inputs.password}
        />
      </div>
      <div className="group flex space-between y-center">
        <input type="submit" name="" className="btn-dark" value="Login" />
        <span
          onClick={() =>
            dispatch({ type: OPEN_MODAL, payload: props.currentModal })
          }
        >
          Create new account?
        </span>
      </div>
    </form>
  );
};

export default Login;
