import React from "react";

const DestinationInfo = ({ details }) => {
  return (
    <div className="destinationInfo">
      <div className="container">
        <h2 className="heading animation">Overview</h2>
        <div className="row">
          <div className="col-8">
            <p className="destinationInfo__p animation">{details.details}</p>
          </div>
        </div>
        <h2 className="heading animation">Good to know</h2>
        <div className="row">
          <div className="col-8">
            <div className="destinationInfo__details">
              <div className="destinationInfo__details_head animation">
                Language
              </div>
              <div className="destinationInfo__details__text animation">
                {details.language}
              </div>
            </div>
            <div className="destinationInfo__details">
              <div className="destinationInfo__details_head animation">
                Currency
              </div>
              <div className="destinationInfo__details__text animation">
                {details.currency}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DestinationInfo;
