import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import PageContainer from "../components/pages-parts/PageContainer";
import AboutImage from "../components/pages-parts/AboutImage";

const About = () => {
  const [state] = useState({
    heading: "about us",
    pageHeading: "world best travel agency company since 2005",
    message:
      "Lorem, ipsum dolor sit amet consectetur adipisicing elit. Consequuntur esse est nam voluptatum corrupti animi vitae minus accusamus numquam quod eligendi, eos tempora dolores, eveniet, nobis dolorum deleniti sint sed! Qui, veritatis aut. Atque officia, dolorem quo illo cumque soluta, facilis voluptatum libero eos unde minus consequuntur ab, nam earum.",
  });

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <Helmet>
        <title>Travel Friends | About</title>
        <meta name="description" content="Travel friends about page" />
      </Helmet>
      <PageContainer data={state}>
        <AboutImage />
      </PageContainer>
    </>
  );
};

export default About;
