const services = [
  {
    id: 1,
    heading: "Travel guides",
    p: "In each country we have travel guides, they will help you throughout the tour and they are very friendly you can ask them any question and they will never mind.",
  },
  {
    id: 2,
    heading: "5 star hotels",
    p: "We have contracted with 5-star hotels in the available destinations, in those hotels, you will have all facilities ",
  },
  {
    id: 3,
    heading: "Free diner",
    p: "Some of our packages have free diner for that you can check our packages",
  },
  {
    id: 4,
    heading: "Rooms for couples",
    p: "We have good facilities for couples, in 5-star hotels, we room for couples, that's why peoples love our tours. ",
  },
];

export default services;
