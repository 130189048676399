import React, { useState } from "react";
import { BiChevronRight } from "react-icons/bi";

const ServicesList = ({ services }) => {
  const [current, setCurrent] = useState(0);

  const showDetails = (index) => {
    setCurrent(index);
  };
  return services.length > 0
    ? services.map((service, index) => (
        <div className="services__list animation" key={service.id}>
          <h4
            className={`services__list__heading ${
              current === service.id
                ? "services__list__heading--active"
                : "services__list__heading--normal"
            }`}
            onClick={() => showDetails(service.id)}
          >
            <BiChevronRight
              size={20}
              className={`${
                current === service.id ? "services__rotate" : "services__back"
              }`}
            />
            {service.heading}
          </h4>
          <p
            className={`services__list__details ${
              current === service.id ? "services__list__details--show" : ""
            }`}
          >
            {service.p}
          </p>
        </div>
      ))
    : "";
};

export default ServicesList;
