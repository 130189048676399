import React, { useReducer } from "react";
import ModalContext from "../ModalContext";
import ModalReducer from "../reducers/ModalReducer";

const ModalProvider = (props) => {
  //console.log(props.children);
  const [state, dispatch] = useReducer(ModalReducer, {
    modalStatus: false,
    current: "",
  });
  /* const openModal = () => {
    dispatch({ modalStatus: true });
  };
  const closeModal = () => {
    dispatch({ modalStatus: false });
  };
 */
  return (
    <ModalContext.Provider value={{ state, dispatch }}>
      {props.children}
    </ModalContext.Provider>
  );
};

export default ModalProvider;
